import { graphql, withPrefix } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { useI18next, useTranslation } from "gatsby-plugin-react-i18next"
import React, { useEffect, useState } from "react"
import Scroll from "react-scroll"
import { useSwipeable } from "react-swipeable"
import links from "../../content/links/links.json"
import Layout from "../components/layout"
import MenuBottom from "../components/menuBottom"
import Seo from "../components/seo"
// @ts-ignore
import arrowDownSVG from "../images/arrow-down.svg"
// @ts-ignore
import unisexSVG from "../images/unisex.svg"
import findImage from "../utils/findImage"
import getImages from "../utils/getImages"

const CategoryTemplate = props => {
  const { data, location } = props

  const [index, setIndex] = useState(0)
  const { t } = useTranslation()
  const { language } = useI18next()

  const content = data.categoryJson?.content
  const slug = data.categoryJson?.slug
  const title = data.categoryJson?.title
  const mobileImages = data.mobileImages
  const tabletImages = data.tabletImages
  const desktopImages = data.desktopImages

  const siteTitle = title || data.site.siteMetadata?.title

  var Element = Scroll.Element
  var scroller = Scroll.scroller

  const scroll = element => {
    scroller.scrollTo(element, {
      duration: 700,
      delay: 0,
      smooth: true,
    })
  }

  const config = {
    onSwipedUp: eventData => {
      console.log("onSwipedUp", eventData)
      setIndex(prevIndex =>
        prevIndex + 1 === content?.length ? prevIndex : prevIndex + 1
      )
    },
    onSwipedDown: eventData => {
      console.log("onSwipedDown", eventData)

      setIndex(prevIndex => (prevIndex - 1 < 0 ? 0 : prevIndex - 1))
    },
  }

  const handlers = useSwipeable(config)

  const [menu1, setMenu1] = useState<undefined | number>()
  const [menu2, setMenu2] = useState<undefined | number>()
  const [countryCode, setCountryCode] = useState<String>("DEFAULT")

  useEffect(() => {
    scroll(`content${index}`)
    if (index === 0) {
      setMenu1(index + 1 < content.length ? index + 1 : undefined)
      setMenu2(index + 2 < content.length ? index + 2 : undefined)
    } else {
      if (index + 1 < content.length) {
        setMenu1(index + 1)
        setMenu2(0)
      } else {
        let newIndex =
          index - 1 < content.length && index - 1 > 0 ? index - 1 : undefined
        if (newIndex === undefined) {
          setMenu1(0)
          setMenu2(undefined)
        } else {
          setMenu1(newIndex)
          setMenu2(0)
        }
      }
    }
  }, [index, content])

  useEffect(() => {
    fetch("https://extreme-ip-lookup.com/json/?key=oeZp6aXnb44YXr7R9sEk")
      .then(res => res.json())
      .then(response => setCountryCode(response?.countryCode))
      .catch(error => setCountryCode("DEFAULT"))
  }, [])

  const arrowUp = index > 0 && (
    <div className="content-arrow arrow-top content-arrow-none">
      <img
        className="bounce"
        src={arrowDownSVG}
        alt="Arrow up"
        onClick={() => setIndex(prevIndex => prevIndex - 1)}
      />
    </div>
  )

  return (
    <Layout location={location} title={siteTitle} headerMiddleContent={arrowUp}>
      <Seo title={siteTitle} />
      <div {...handlers}>
        {content?.map(
          (
            {
              title,
              title2,
              subtitle,
              img,
              buyLink,
              logo,
              backgroundColor,
              justify,
            },
            currentIndex
          ) => {
            const images = getImages(
              findImage(mobileImages, img),
              findImage(tabletImages, img),
              findImage(desktopImages, img)
            )
            return (
              <Element
                name={`content${currentIndex}`}
                key={`content${currentIndex}`}
              >
                <div
                  className="category"
                  style={backgroundColor && { backgroundColor }}
                >
                  <div className="category-block">
                    <GatsbyImage
                      className="category--background"
                      image={images}
                      alt={title || "Background image"}
                    />
                  </div>
                  <div
                    className={`category-block category-content ${
                      justify ? `justify-${justify}` : ""
                    }`}
                  >
                    {title && (
                      <div
                        className="category--title"
                        dangerouslySetInnerHTML={{ __html: title }}
                      ></div>
                    )}
                    {title2 && (
                      <div
                        className="category--title category--title2"
                        dangerouslySetInnerHTML={{ __html: title2 }}
                      ></div>
                    )}

                    {subtitle && (
                      <>
                        <div className="category--divider">
                          <div className="category--divider-item"></div>
                        </div>
                        <div className="category--label-container">
                          <p
                            className="category--label"
                            dangerouslySetInnerHTML={{ __html: subtitle }}
                          ></p>
                        </div>
                      </>
                    )}
                    {logo === "unisex" && (
                      <div className="category--logo-container">
                        <img
                          className="category--logo"
                          src={unisexSVG}
                          alt={`Logo ${title}`}
                        />
                      </div>
                    )}
                    {buyLink && (
                      <div className="category--buy">
                        <a
                          className="nav--item"
                          href={links[`${countryCode}`][`${buyLink}`]}
                          children={
                            <img
                              className="category--buy-image"
                              src={withPrefix(
                                `/images/amazon/buy-${language}.png`
                              )}
                              alt={t("other.buy")}
                            />
                          }
                        />
                      </div>
                    )}
                  </div>
                </div>
              </Element>
            )
          }
        )}
      </div>
      <MenuBottom
        first={undefined}
        second={undefined}
        third={
          menu1 >= 0 &&
          content[menu1].name && (
            <div className={"bottom-menu-buttom beauty"}>
              <span onClick={() => setIndex(menu1)}>{content[menu1].name}</span>
            </div>
          )
        }
        fourth={
          menu2 >= 0 &&
          content[menu2].name && (
            <div className={"bottom-menu-buttom sex"}>
              <span onClick={() => setIndex(menu2)}>{content[menu2].name}</span>
            </div>
          )
        }
      />
      {index < content?.length - 1 && (
        <div className="content-arrow arrow-down content-arrow-none">
          <img
            className="bounce"
            src={arrowDownSVG}
            alt="Arrow down"
            onClick={() => setIndex(prevIndex => prevIndex + 1)}
          />
        </div>
      )}
    </Layout>
  )
}

export default CategoryTemplate

export const pageQuery = graphql`
  query CategoryByKey($key: String!, $language: String!) {
    site {
      siteMetadata {
        title
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    categoryJson(key: { eq: $key }, language: { eq: $language }) {
      title
      slug
      content {
        name
        title
        title2
        subtitle
        img
        buyLink
        logo
        backgroundColor
        justify
      }
    }
    mobileImages: allFile(
      filter: { sourceInstanceName: { eq: "content-images" } }
    ) {
      edges {
        node {
          name
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
          }
        }
      }
    }
    tabletImages: allFile(
      filter: { sourceInstanceName: { eq: "content-images-tablet" } }
    ) {
      edges {
        node {
          name
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
          }
        }
      }
    }
    desktopImages: allFile(
      filter: { sourceInstanceName: { eq: "content-images-desktop" } }
    ) {
      edges {
        node {
          name
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
          }
        }
      }
    }
  }
`
