import { withArtDirection } from "gatsby-plugin-image"

const getImages = (mobileImage, tabletImage, desktopImage) => {
  return withArtDirection(desktopImage, [
    {
      media: "(max-width: 1024px)",
      image: tabletImage,
    },
    {
      media: "(max-width: 443px)",
      image: mobileImage,
    },
  ])
}

export default getImages
