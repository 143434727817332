import * as React from "react"

const MenuBottom = ({ first, second, third, fourth }) => {
  return (
    <div className="bottom-menu">
      <div className="bottom-menu-first">{first}</div>
      <div className="bottom-menu-second">{second}</div>
      <div className="bottom-menu-third">{third}</div>
      <div className="bottom-menu-fourth">{fourth}</div>
    </div>
  )
}

export default MenuBottom
